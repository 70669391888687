// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/home/vsts/work/1/build-bundle-dsp-admin-extract/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@ant-design/pro-layout/es/PageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 't__plugin-layout__Layout' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/.umi-production/plugin-layout/Layout.tsx'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/user",
        "layout": false,
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/user/Login'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index",
        "name": "index",
        "icon": "HomeOutlined",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__index' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/index'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/advert",
        "name": "advert",
        "icon": "ShareAltOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/advert/campaign",
            "name": "campaign",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__advert__campaign' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/advert/campaign'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/advert/unit",
            "name": "unit",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__advert__unit' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/advert/unit'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/advert/log",
            "name": "log",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__advert__log' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/advert/log'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/customer",
        "name": "customer",
        "icon": "UserOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/customer/management",
            "name": "manage",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__management' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/customer/management'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/customer/log",
            "name": "log",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__customer__log' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/customer/log'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/report",
        "name": "report",
        "icon": "LineChartOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/report/repcustomer",
            "name": "repcustomer",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__customer' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/report/customer'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/report/system",
            "name": "system",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__report__system' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/report/system'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/finance",
        "name": "finance",
        "icon": "DollarCircleOutlined",
        "access": "canAdmin",
        "Routes": [
          "./routes/"
        ],
        "routes": [
          {
            "path": "/finance/fincustomer",
            "name": "fincustomer",
            "icon": "smile",
            "hideChildrenInMenu": true,
            "routes": [
              {
                "path": "/finance/fincustomer/",
                "name": "fincustomer",
                "exact": true,
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__fincustomer__fincustomer' */'@/pages/finance/fincustomer/fincustomer'), loading: LoadingComponent})
              },
              {
                "path": "/finance/fincustomer/prepaidhistory",
                "name": "prepaid-history",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__fincustomer__prepaidhistory' */'@/pages/finance/fincustomer/prepaidhistory'), loading: LoadingComponent}),
                "access": "canAdmin",
                "exact": true
              },
              {
                "path": "/finance/fincustomer/dailystatement",
                "name": "daily-statement",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__fincustomer__dailystatement' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/finance/fincustomer/dailystatement'), loading: LoadingComponent}),
                "access": "canAdmin",
                "exact": true
              }
            ]
          },
          {
            "path": "/finance/system",
            "name": "system",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__finance__system' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/finance/system'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/system",
        "name": "system",
        "icon": "FundProjectionScreenOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/system/account",
            "name": "account",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__account' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/system/account'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/power",
            "name": "power",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__power' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/system/power'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/system/log",
            "name": "log",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__log' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/system/log'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/setting",
        "name": "setting",
        "icon": "SettingOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/setting/parameter",
            "name": "parameter",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__system__parameter' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/system/parameter'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/assist",
        "name": "assist",
        "icon": "ApartmentOutlined",
        "access": "canAdmin",
        "routes": [
          {
            "path": "/assist/bidhelper",
            "name": "bidhelper",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__assist__bidhelper' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/assist/bidhelper'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/assist/minibidhelper",
            "name": "minibidhelper",
            "icon": "smile",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__assist__minibidhelper' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/assist/minibidhelper'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/index.html",
        "redirect": "/index",
        "exact": true
      },
      {
        "path": "/",
        "redirect": "/index",
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/home/vsts/work/1/build-bundle-dsp-admin-extract/src/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
