// @ts-nocheck

  import HomeOutlined from '@ant-design/icons/es/icons/HomeOutlined';
import ShareAltOutlined from '@ant-design/icons/es/icons/ShareAltOutlined';
import SmileOutlined from '@ant-design/icons/es/icons/SmileOutlined';
import UserOutlined from '@ant-design/icons/es/icons/UserOutlined';
import LineChartOutlined from '@ant-design/icons/es/icons/LineChartOutlined';
import DollarCircleOutlined from '@ant-design/icons/es/icons/DollarCircleOutlined';
import FundProjectionScreenOutlined from '@ant-design/icons/es/icons/FundProjectionScreenOutlined';
import SettingOutlined from '@ant-design/icons/es/icons/SettingOutlined';
import ApartmentOutlined from '@ant-design/icons/es/icons/ApartmentOutlined'
  export default {
    HomeOutlined,
ShareAltOutlined,
SmileOutlined,
UserOutlined,
LineChartOutlined,
DollarCircleOutlined,
FundProjectionScreenOutlined,
SettingOutlined,
ApartmentOutlined
  }