const BACKEND_API = {
  LOGIN: '/login',
  FINANCE_SYSTEM_REPORT: '/finance_report/system_financial_statement',
  FINANCE_ALL_CUSTOMER_REPORT: '/finance_report/all_customer_financial_statement',
  FINANCE_CUSTOMER_DAILY_STATEMENT: '/finance_report/customer_daily_statement',
  FINANCE_CUSTOMER_PREPAID_HISTORY: '/finance_report/customer_prepaid_history',
  FINANCE_PREPAID: '/finance_report/prepaid',
  Customer: '/customer',
  QualificationSave: '/system/qualification/save',
  QualificationStatus: '/system/qualification/status',
  QualificationAll: '/system/qualification/all',
  FindParameter: '/system/parameter/find',
  UpdateParameter: '/system/personalnotice/update',
  ChargMethodSave: '/system/chargmethod/save',
  ChargMethodStatus: '/system/chargmethod/status',
  ChargMethodAll: '/system/chargmethod/all',
  ChargeMethodsAllActive: '/system/chargemethod/all_active',
  AdSizeSave: '/system/adsize/save',
  AdSizeDelete: '/system/adsize/delete/',
  AdSizeStatus: '/system/adsize/status/',
  AdSizeAll: '/system/adsize/all',
  CompanyAllSelect: '/company/all/select',
  CompanyAllSelectDesc: '/company/all/select/desc',
  CompanyAllCampaign: '/adver/campaign/all',
  //compaign and unit apis
  CompanyAllOptions: '/company/all/options',
  ADVERT_CAMPAIGNS: '/adver/campaign',
  ADVERT_CAMPAIGNS_ADD: '/adver/campaign/add',
  ADVERT_CAMPAIGNS_EDIT: '/adver/campaign/edit',
  ADVERT_CAMPAIGNS_STATUS: '/adver/campaign/update/status',
  ADVERT_CAMPAIGNS_ADD_LOG: '/adver/campaign/log/add',
  ADVERT_CAMPAIGNS_ALL: '/adver/campaigns',
  ADVERT_UNITS: '/adver/unit',
  ADVERT_UNIT_STATUS: '/adver/unit/update/status',
  ADVERT_UNIT_ADD_LOG: '/adver/unit/log/add',
  ADVERT_UNIT_ADD: '/adver/unit/add',
  ADVERT_UNIT_UPDATE: '/adver/unit/edit',
  ADVERT_UNIT_FIND_BY_ID: '/adver/unit/findById',
  ADVERT_COUNTRY_CITY_LIST: '/adver/country/cities',
  UPLOAD: '/fileupload',
  LANGUAGE: '/adver/language',
  ADVERT_SIZES: '/adver/sizes',
  ADVERT_DRAWER_DATA: '/adver/drawer/data'
};

const DSP_BACKEND_API = {
  Openrtb: '/openrtb',
};

export { BACKEND_API, DSP_BACKEND_API };
