// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import ShareAltOutlined from '@ant-design/icons/ShareAltOutlined';
import SmileOutlined from '@ant-design/icons/SmileOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';
import LineChartOutlined from '@ant-design/icons/LineChartOutlined';
import DollarCircleOutlined from '@ant-design/icons/DollarCircleOutlined';
import FundProjectionScreenOutlined from '@ant-design/icons/FundProjectionScreenOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import ApartmentOutlined from '@ant-design/icons/ApartmentOutlined'

export default {
  HomeOutlined,
ShareAltOutlined,
SmileOutlined,
UserOutlined,
LineChartOutlined,
DollarCircleOutlined,
FundProjectionScreenOutlined,
SettingOutlined,
ApartmentOutlined
}
    