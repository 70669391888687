import { SettingDrawer, Settings as LayoutSettings } from '@ant-design/pro-layout';
import { PageLoading } from '@ant-design/pro-layout';
import type { RunTimeLayoutConfig } from 'umi';
import { history } from 'umi';
import RightContent from '@/components/RightContent';
import Footer from '@/components/Footer';
import { getCurrentUser } from './services/user.service';
import defaultSettings from '../config/defaultSettings';
import * as Cache from './utils/cache.helper';
import { STORAGE_KEY } from './utils/consts';

const loginPath = '/user/login';

/** 获取用户信息比较慢的时候会展示一个 loading */
export const initialStateConfig = {
  loading: <PageLoading />,
};

export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  userInfo?: API.CurrentUser;
  loading?: boolean;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = () => {
    try {
      const user = getCurrentUser();
      if (!user) {
        history.push(loginPath);
      }
      return user;
    } catch (error) {
      history.push(loginPath);
    }
    return undefined;
  };

  const userInfo = await fetchUserInfo();

  return {
    fetchUserInfo,
    userInfo,
    settings: defaultSettings,
  };
}

export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  return {
    rightContentRender: () => <RightContent />,
    disableContentMargin: false,
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      // 如果没有登录，重定向到 login
      const tokenExpired = Cache.getObject(STORAGE_KEY.TOKEN_EXPIRED)
      if (!initialState?.userInfo && location.pathname !== loginPath || tokenExpired) {
        history.push(loginPath);
      } else if (initialState?.userInfo && location.pathname == loginPath) {
        history.push('/');
      }
    },
    links: [],
    menuHeaderRender: undefined,
    childrenRender: (children, props) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <>
          {children}
          {!props.location?.pathname?.includes('/login') && (
            <SettingDrawer
              disableUrlParams
              enableDarkTheme
              hideCopyButton
              hideHintAlert
              settings={initialState?.settings}
              onSettingChange={(settings) => {
                setInitialState((preInitialState) => ({
                  ...preInitialState,
                  settings,
                }));
              }}
            />
          )}
        </>
      );
    },
    ...initialState?.settings,
  }
};
