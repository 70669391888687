const STORAGE_KEY = {
  TOKEN: 'USER_TOKEN',
  USER_INFO: 'USER_INFO',
  TIME_ZONE: 'TIME_ZONE',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED'
};

const TIME_ZONE = {
  'UTC-4:00': -4,
  'UTC+8:00': 8,
};

const CITIES = {
  Montreal: 'UTC-4:00',
  Beijing: 'UTC+8:00',
};

export { STORAGE_KEY, TIME_ZONE, CITIES };
