import { extend } from 'umi-request';
import { config } from '../environment';
import * as Cache from './cache.helper';
import { STORAGE_KEY } from './consts';
import { history } from 'umi';
import { stringify } from 'querystring';

function useRequest() {
  return wrap("admin")
}

export function useCustomerRequest() {
  return wrap("customer")
}

export function useDspRequest() {
  const request = extend({
    prefix: `${config.dspAPI}/api/v1/dsp`,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json'
    },
    errorHandler(error) {
      const { response } = error;
      if (response && response.status) {
          if (response.status === 401 && window.location.pathname !== '/user/login') {
            Cache.remove(STORAGE_KEY.USER_INFO);
            Cache.remove(STORAGE_KEY.TOKEN);
            Cache.putObject(STORAGE_KEY.TOKEN_EXPIRED, true);
            history.push('/')
          }
      }
    }
  });

  return request;
}

function wrap(apiPart: string){
  const token = Cache.getString(STORAGE_KEY.TOKEN);

  const request = extend({
    prefix: `${config.backendAPI}/api/v1/${apiPart}`,
    timeout: 10000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: token || '',
    },
    errorHandler(error) {
      const { response } = error;
      if (response && response.status) {
          if (response.status === 401 && window.location.pathname !== '/user/login') {
            Cache.remove(STORAGE_KEY.USER_INFO);
            Cache.remove(STORAGE_KEY.TOKEN);
            Cache.putObject(STORAGE_KEY.TOKEN_EXPIRED, true);
            history.push('/')
          }
      }
    }
  });

  return request;
}

export default useRequest;
